.wr-site {
    .pg {
        .pg-title {
            width: 100%;
            display: grid;
            grid-gap: 20px;
            grid-template-columns: auto min-content;
            padding: 0 10px 32px 0;
            box-sizing: border-box;
        }
    }
}

* {
    -webkit-tap-highlight-color: transparent;
}

.mat-mdc-form-field {
    .mat-mdc-form-text-infix {
        width: auto;
    }
    .mat-mdc-select-arrow {
        color: transparent !important;
        position: relative;
        margin: 0;
        width: 18px;

        &::before {
            content: '\e149';
            color: var(--color_primary);
            font-family: 'bs2-font-icon-v1';
            font-size: 24px;
            font-weight: normal;
            left: 50%;
            line-height: 0;
            transform: translate(-50%, 0);
        }
    }

    &.small {
        .mat-mdc-form-text-infix {
            border-top: 0.3em solid transparent;

            label {
                margin-top: 0;
            }
        }
        .mat-mdc-select-arrow {
            margin-top: 4px;
        }
    }
}

.bx-not-found {
    background-color: #fff;
    width: 100%;
    position: relative;
    padding: 20px 0;
    color: var(--color_gray_3);
    font-size: 12px;
    text-align: center;

    h4 {
        font-size: 14px;
        color: var(--color_default_black);
        font-weight: 600;
        margin: 0 0 10px 0;
    }

    span {
        display: block;
        margin: 15px 0 0 0;
        color: #5050d2;
        cursor: pointer;
    }
}

.bx-error {
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .bx-error-container {
        width: calc(100% - 100px);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: var(--color_gray_3);
        font-size: 12px;
        text-align: center;

        h4 {
            font-size: 14px;
            color: var(--color_default_black);
            font-weight: 600;
            margin: 0 0 10px 0;
        }

        span {
            display: block;
            margin: 15px 0 0 0;
            color: #5050d2;
            cursor: pointer;
        }
    }
}

/* Material overrides */

.mat-icon.mat-primary {
    color: var(--color_primary);
}

.mat-form-field-appearance-outline.mat-focused .mdc-notched-outline {
    color: var(--color_primary);
}

.mat-mdc-form-field.mat-focused .mat-mdc-floating-label:not(.mat-form-field-invalid) {
    color: var(--color_primary);
}

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
.mat-progress-bar-fill::after {
    background-color: var(--color_primary);
}

.mat-mdc-checkbox-checked.mat-primary .mdc-checkbox__background,
.mat-checkbox-indeterminate.mat-primary .mdc-checkbox__background {
    background-color: var(--color_primary);
}

.mat-mdc-checkbox:not(.mat-checkbox-disabled).mat-primary .mat-checkbox-ripple .mdc-switch__ripple {
    background-color: var(--color_primary);
}

.mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked .mdc-radio__outer-circle {
    border-color: var(--color_primary);
}

.mat-mdc-radio-button.mat-accent .mdc-radio__inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mdc-switch__ripple:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-mdc-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: var(--color_primary);
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-search-clear {
    width: 20px !important;
    height: 20px !important;
    top: 10px !important;
    right: 10px !important;

    span {
        .mat-icon {
            font-size: 12px !important;
        }
    }
}

.cdk-overlay-container {
    z-index: 10000;
}

.mat-mdc-optgroup {
    &.bloqueado {
        label {
            &:after {
                content: ' bloqueado';
                font-size: 10px;
                color: var(--color_system_error);
            }
        }
    }
}

.mat-mdc-tooltip {
    font-size: 12px;
}

table {

    td,
    th {
        &.align-right {
            text-align: right;
        }
    }
}

.mat-badge-accent .mat-badge-content {
    background: var(--color_system_error);
    color: var(--color_default_white);
    font-weight: 500;
}

// Levar para utilities futuramente

.mat-mdc-slide-toggle .mdc-switch__handle {
    background-color: var(--color_primary);
}

.mat-mdc-slide-toggle .mdc-switch {
    background-color: var(--color_gray_3);
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked .mdc-switch__handle {
    background-color: var(--color_default_white);
    border: solid 1px var(--color_primary);
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked .mdc-switch {
    background-color: var(--color_primary);
}

.mdc-label {
    color: var(--color_gray_3);
}

.material-icons {
    font-family: 'Material Icons' !important;
    font-weight: normal !important;
    font-style: normal !important;
}

.bx-uploads {
    /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
    .bs2-progress-bar-erro .mat-progress-bar-fill:after {
        background-color: var(--color_system_error);
    }
    /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
    .bs2-progress-bar-success .mat-progress-bar-fill:after {
        background-color: var(--color_system_success);
    }

    .mat-expansion-panel-header {
        min-height: 64px !important;
        height: auto !important;
        background-color: var(--color_gray_1) !important;
        padding: 10px 20px !important;
        display: flex;
        align-items: center;

        p {
            height: auto;
        }
    }

    .mat-expansion-panel-content {
        .mat-expansion-panel-body {
            padding: 0 !important;
            /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
            /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
            .mat-button-toggle {
                width: 100%;
                /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
                .mat-button-toggle-button {
                    height: 100%;
                }
                /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
                &.mat-button-toggle-checked {
                    background-color: var(--color_primary);
                    color: var(--color_default_white);
                    font-weight: bold;
                }
            }
        }
    }
}

.embeddedServiceHelpButton .helpButton .uiButton {
    background-color: #3333cc !important;
    font-family: 'Salesforce Sans', sans-serif !important;
}

.embeddedServiceHelpButton .helpButton .uiButton:focus {
    outline: 1px solid #3333cc !important;
}

@font-face {
    font-family: 'Salesforce Sans';
    src: url('https://c1.sfdcstatic.com/etc/clientlibs/sfdc-aem-master/clientlibs_base/fonts/SalesforceSans-Regular.woff') format('woff'),
        url('https://c1.sfdcstatic.com/etc/clientlibs/sfdc-aem-master/clientlibs_base/fonts/SalesforceSans-Regular.ttf') format('truetype');
}


.mat-mdc-form-field:not(.mat-mdc-paginator-page-size-select) .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label{
	top:26px !important;
} 